import RepairPart from './RepairPart';

export default class CompletedRepairPart {
    public quantity: number = 0;

    public repairPartId: number = 0;

    public repairPart: RepairPart = new RepairPart();

    public repairPartCost: number = 0;

    constructor(init?: Partial<CompletedRepairPart>) {
        Object.assign(this, init);
    }
}
