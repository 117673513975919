import axios, { AxiosResponse } from 'axios';
import CompletedRepair from '@/domain/Repair/CompletedRepair';
import Repair from '@/domain/Repair/Repair';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from './dataAccessResponse';

export default class RepairApiService {
    public async getItemInHouseRepairs(itemId: number): Promise<DataAccessResponse<Repair[]>> {
        const response: AxiosResponse<DataAccessResponse<Repair[]>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/repair/in-house/${itemId}`,
        });

        return new DataListAccessResponseClass<Repair>(response, Repair).response;
    }

    public async submitCompletedRepair(completedRepair: CompletedRepair): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            data: completedRepair,
            url: `${API_SERVICE_URL}/repair/completed`,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async getItemInLineRepairs(itemId: number): Promise<DataAccessResponse<Repair[]>> {
        const response: AxiosResponse<DataAccessResponse<Repair[]>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/repair/in-line/${itemId}`,
        });

        return new DataListAccessResponseClass<Repair>(response, Repair).response;
    }
}
