
import { defineComponent, PropType, computed } from 'vue';

type JustifyContentDirection = 'flex-end' | 'space-between'

export default defineComponent({
    name: 'smart-trak-footer',
    props: {
        contentDirection: {
            type: String as PropType<JustifyContentDirection>,
            default: 'flex-end',
        },
    },
    setup(props) {
        const contentCss = computed(() => ({
            'justify-content': props.contentDirection,
            gap: '5px',
        }));

        return {
            contentCss,
        };
    },
});
