import CompletedRepair from '@/domain/Repair/CompletedRepair';
import Repair from '@/domain/Repair/Repair';
import RepairApiService from './api/RepairApiService';

export default class RepairService {
    private apiService: RepairApiService = new RepairApiService();

    public async getItemInHouseRepairs(itemId: number): Promise<Repair[]> {
        const response = await this.apiService.getItemInHouseRepairs(itemId);

        return response.data;
    }

    public async submitCompletedRepair(completedRepair: CompletedRepair): Promise<number> {
        const response = await this.apiService.submitCompletedRepair(completedRepair);

        return response.data;
    }

    public async getItemInLineRepairs(itemId: number): Promise<Repair[]> {
        const response = await this.apiService.getItemInLineRepairs(itemId);

        return response.data;
    }
}
