import Item from '@/domain/Item';

export default class UnitLoadPart {
    public id: number;

    public isPrimaryPlanningItem: boolean;

    public itemId: number;

    public part: Item;

    public quantity: number;

    constructor(init: Partial<UnitLoadPart>) {
        this.id = init?.id || 0;
        this.isPrimaryPlanningItem = !!(init?.isPrimaryPlanningItem);
        this.itemId = init?.itemId || 0;
        this.part = new Item(init?.part);
        this.quantity = init?.quantity || 0;
    }

    get partId(): number {
        return this.part.id;
    }

    toJSON() {
        return {
            id: this.id,
            isPrimaryPlanningItem: this.isPrimaryPlanningItem,
            itemId: this.itemId,
            partId: this.partId,
            quantity: this.quantity,
        };
    }
}
