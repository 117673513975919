import ItemType from '@/domain/ItemType';
import UnitLoadPart from './UnitLoadPart';
import { API_SERVICE_URL } from '@/config/env';
import { AuthStore } from '@/store/AuthStore';

export default class Item {
    constructor(init?: Partial<Item>) {
        this.displayName = init?.displayName || '';
        this.searchByName = init?.searchByName || '';
        this.quantity = init?.quantity || 0;
        this.id = init?.id || 0;
        this.length = init?.length || 0;
        this.width = init?.width || 0;
        this.height = init?.height || 0;
        this.weight = init?.weight || 0;
        this.collapsedHeight = init?.collapsedHeight || 0;
        this.palletQty = init?.palletQty || 0;
        this.shortName = init?.shortName || '';
        this.isUnitLoad = init?.isUnitLoad || false;
        if (init?.type) {
            this.type = init?.type;
        }
        this.imageUrlFull = init?.imageFileName ? `${API_SERVICE_URL}/storage/images/${AuthStore.getInstance().clientId}/${init?.imageFileName}` : undefined;
        this.imageUrlThumb = init?.imageFileName ? `${API_SERVICE_URL}/storage/thumbnails/${AuthStore.getInstance().clientId}/${init?.imageFileName}` : undefined;
        this.name = init?.name || '';
        this._collapsible = init?.collapsible || false;
        this.unitLoadParts = init?.unitLoadParts?.map((itemPart) => new UnitLoadPart(itemPart)) || [];
        this.unitCost = init?.unitCost;
        this.customerItemNumber = init?.customerItemNumber;
        this.imageFileName = init?.imageFileName;
        this.floorLocation = init?.floorLocation;
        this.stackHeight = init?.stackHeight;
        this.fullTrailerLoad = init?.fullTrailerLoad;
    }

    // for floortrak
    public displayName!: string;

    public searchByName!: string;

    public quantity = 0;

    public id: number = 0;

    public length!: number;

    public width!: number;

    public height!: number;

    public _collapsible: boolean = false;

    public collapsedHeight?: number; // If collapsible = True then NOT NULL

    public weight!: number;

    public name!: string;

    public shortName!: string;

    public image?: string; // base64 encoded image

    public type!: ItemType;

    public isUnitLoad!: boolean;

    public palletQty!: number;

    public unitLoadParts: UnitLoadPart[] = [];

    public unitCost?: number;

    public customerItemNumber?: string;

    public imageFileName?: string;

    public imageUrlFull?: string;
    public imageUrlThumb?: string;

    public floorLocation?: string;

    public stackHeight?: number;

    public fullTrailerLoad?: number;

    get containerVolume(): number {
        return this.length * this.width * this.height;
    }

    get collapsedVolume(): number | null {
        if (this.collapsible && this.collapsedHeight) {
            return this.length * this.width * this.collapsedHeight;
        }
        return null;
    }

    get collapsible(): boolean {
        return this._collapsible;
    }

    set collapsible(value: boolean) {
        if (!value) {
            delete this.collapsedHeight;
        }
        this._collapsible = value;
    }

    get nameAndNumber(): string {
        return !this.name ? '' : [this.name, this.customerItemNumber].join(' | ');
    }

    // include private members that rely on getters in the payload when we call JSON.Stringify
    toJSON() {
        return { ...{ ...this }, collapsible: this.collapsible };
    }

    public addPartToUnitLoad(part: Item, quantity: number) {
        if (this.isUnitLoad) {
            this.unitLoadParts.push(new UnitLoadPart({
                itemId: this.id,
                part,
                quantity,
            }));
        }
    }

    public removePartFromUnitLoad(partId: number) {
        this.unitLoadParts = this.unitLoadParts.filter((itemPart) => itemPart.partId !== partId);
    }

    public setPrimaryPlanningPartForUnitLoad(partId: number, newValue: boolean) {
        this.unitLoadParts.forEach((part) => { part.isPrimaryPlanningItem = part.partId === partId ? newValue : false; });
    }
}
